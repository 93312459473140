/********************************
 * Full-viewport Snow Container
 ********************************/
 .snowy-background {
  // Use FIXED to ensure it stays behind everything as you scroll.
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 1000; // adjust if needed so the snow is behind or above certain elements
}

/********************************
 * Individual Snowflakes
 ********************************/
.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}

/********************************
 * Random Range Function (SCSS)
 ********************************/
@function random_range($min, $max) {
  $rand: random();
  @return $min + floor($rand * (($max - $min) + 1));
}

/********************************
 * Create 200 Snowflakes
 ********************************/
.snow {
  $total: 200;

  @for $i from 1 through $total {
    // random X/Y from 0 to 100 (in viewport units)
    $random-x: random(100000) * 0.001; // yields 0..100
    $random-y: random(100000) * 0.001; // yields 0..100

    // horizontal offset for the drifting motion
    $random-offset: random_range(-50, 50); // in vw
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);

    // at which point in the animation the flake "yoyo"s horizontally
    $random-yoyo-time: random_range(0, 100000) / 100000; // 0.3..0.8
    $random-yoyo-y: $random-yoyo-time * 100; // 10..80 vh

    // random scale & animation durations
    $random-scale: random(10000) * 0.0001; // 0..1
    $fall-duration: random_range(10, 30) * 1s; // 10..30 seconds
    $fall-delay: random(30) * -1s;           // random negative delay up to -29s

    &:nth-child(#{$i}) {
      // each flake gets random opacity
      opacity: random(10000) * 0.0001;

      // initial position & size
      transform: translate(#{$random-x}vw, #{$random-y}vh) scale($random-scale);

      // apply the falling animation
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    // define the flake's unique keyframes
    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate(#{$random-x-end}vw, #{$random-yoyo-y}vh) scale($random-scale);
      }
      to {
        transform: translate(#{$random-x-end-yoyo}vw, 100vh) scale($random-scale);
      }
    }
  }
}
