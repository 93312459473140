$salestier-blue: rgb(12, 69, 166);
$aqua-cyan: rgb(87, 235, 224);
$graphite-grey: rgb(51, 51, 51);
$blue: rgb(3, 13, 78);
body {
  font-family: 'Poppins', sans-serif;
	font-size: 18px;
}
.header-logo {
	height: 42px;
	margin-left: 24px;
}


@keyframes rotating {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotating 4s linear infinite;
}

.shake {
	animation: jiggle 2s infinite ease-in-out;
  }
  
  @keyframes jiggle {
	0%, 100% { transform: rotate(0deg); }
	25%      { transform: rotate(8deg); }
	50%      { transform: rotate(-8deg); }
	75%      { transform: rotate(8deg); }
  }

.deactivatedsplash {
	z-index: 20;
	position: absolute;
	display: flex;
  font-family: 'Poppins', sans-serif;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100vh;
	background-color: #0c45a6;
	// stupidly high z-index to beat toolbar
	z-index: 2000;
	align-items: center;
	justify-content: space-around;
	opacity: 1;
	transition: opacity 0.3s;
	text-align: center;
	&.concise {
		background-color: rgba(0,0,0,0.3);
		.splash-inner {
			border-radius: 20px;
			background-color: #0c45a6;
			padding: 48px;
		}
	}
}

.splash {
	z-index: 20;
	position: absolute;
	display: flex;
  font-family: 'Poppins', sans-serif;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	height: 100vh;

	//original background
	// background-color: #0c45a6;


	// background for christmas
	background: radial-gradient(ellipse at bottom, #074ed2 0%, #0c45a6 100%);
	// stupidly high z-index to beat toolbar
	z-index: 2000;
	align-items: center;
	justify-content: space-around;
	opacity: 1;
	transition: opacity 0.3s;
	text-align: center;
	pointer-events: none;
	&.concise {
		background-color: rgba(0,0,0,0.3);
		.splash-inner {
			border-radius: 20px;
			background-color: #0c45a6;
			padding: 48px;
		}
	}
}

.splash-message {
	padding: 24px 0;
	color: #fff;
	font-size: 20px;
}

.error {
	color: #df2f2f
}

.heading-one {
	font-size: 80px;
	margin:  0 0 50px;
}
.heading-two {
	font-size: 52px;
	margin:  0 0 30px;
}
.heading-three {
	font-size: 34px;
	margin:  0 0 50px;
}
.heading-fieldset {
	font-size: 14px;
	margin-top: 1em;
	font-weight: 600;
}

.st-app-bar {
	&,
	&.MuiAppBar-colorPrimary {
		background-image: none;
		background-color: $salestier-blue;
		// border-bottom: 1px solid lighten($graphite-grey, 50%);
		// box-shadow: inset 0 -1px 0 lighten($graphite-grey, 50%);
	}
}

.st-card-header {
	&,
	&.MuiCardHeader-root{
		background-color: $salestier-blue;
		text-align: center;
		color: #fff;
		padding: 8px;
	}
}

.crumb-link {
	color: $graphite-grey;
}

.region-select-link {
	display: flex;
	align-items: center;
	color: $salestier-blue;
	margin-bottom: 12px;
}

.floating-link {
	font-size: 18px;
}

.report-fast-dates {
	padding-bottom: 12px;
	button {
		margin: 0 4px;
		&:first-of-type {
			margin-left: 1em;
		}
	}
}

.spinner-holder-parent {
	position: relative;
}

.spinner-holder {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.15);
	display: flex;
	align-items: center;
	justify-content: space-around;
}


.button-two {
	&.MuiButton-root {
		color: $blue;
	}
}
div#hubspot-messages-iframe-container {
	pointer-events: none;
	iframe{
		opacity: 0;
		pointer-events: none;
	}
}
.hs-messages-widget-open {
	div#hubspot-messages-iframe-container {
		iframe{
			opacity: 1;
			pointer-events: all;
		}

	}
}
.footer {
	display: flex;
	justify-content: flex-end;
	position: fixed;
	bottom: 0;
	padding-bottom: 1px;
	width: 100%;
	pointer-events: none;
	z-index: 10;
	& > div,
	& > a {
		pointer-events: all;
	}
}

.st-hs-trigger {
	pointer-events: all;
	display: flex;
	height: 56px;
	width: 56px;
	border-radius: 30px;
	background-color: $aqua-cyan;
	justify-content: center;
	align-items: center;
	margin-right: 16px;
	margin-bottom: 16px;
	cursor: pointer;
	&:hover {
		background-color: lighten($aqua-cyan, 10%);
	}
}