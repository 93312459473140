@import "highlevel";
@import "layout";
@import "branding";
@import "pivottable";

@import "@aws-amplify/ui-react/styles.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

html {
    background-color: #f4f6f8;
}

.leaflet-container {
    width: 100%;
    height: 400px;
}

:root,
[data-amplify-theme] {
    --amplify-colors-brand-primary-10: #e7ecf6;
    --amplify-colors-brand-primary-20: #cedaed;
    --amplify-colors-brand-primary-40: #9eb5db;
    --amplify-colors-brand-primary-60: #6d8fca;
    --amplify-colors-brand-primary-80: #3d6ab8;
    --amplify-colors-brand-primary-90: #2458af;
    --amplify-colors-brand-primary-100: #0c45a6;
    --amplify-colors-brand-secondary-10: #eefdfc;
    --amplify-colors-brand-secondary-20: #ddfbf9;
    --amplify-colors-brand-secondary-40: #bcf7f3;
    --amplify-colors-brand-secondary-60: #9af3ec;
    --amplify-colors-brand-secondary-80: #79efe6;
    --amplify-colors-brand-secondary-90: #68ede3;
    --amplify-colors-brand-secondary-100: #57ebe0;
}

.bg-blue {
    background-color: var(--amplify-colors-brand-primary-100);
    height: 100vh;
}
.react-form-builder-preview {
    // height: 62vh !important;
    // min-height: unset !important;
    // overflow-y: scroll;
    .edit-form {
        div {
            .form-group {
                .custom-control~.custom-control {
                    display: none !important;
                }
            }
            .form-group~.form-group {
                display: none !important;
            }
            .dynamic-option-list ul li {
                div:nth-of-type(2),div:nth-of-type(3){
                    display: none !important;
                }
            }
        }
    }
}
.react-form-builder {
    position: relative;
}
.react-form-builder-toolbar{
    margin-top: unset !important;
    top: 212px;
    right: 0;
}

.MuiDataGrid-cell.positive {
    color: green;
    font-weight: bold;
}
.MuiDataGrid-cell.negative {
    color: red;
    font-weight: bold;
}