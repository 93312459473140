.main-toolbar {
	z-index: 10;
};
.state-filter-wrapper {
	&.sticky {
		position: fixed;
		max-height: 86vh;
		overflow: auto;
	}
}
@media screen and (max-width: 1200px) {
	.home-filter-chunk {
		padding: 0 8px;
	}
}

.local-icon {
		width: 100%;
		height: 100%;
	path {
		fill: "#123456";
	}
}

.st-form {
	&.MuiCardContent-root,
	& {
		padding: {
			top: 0;
			bottom: 0;
		}
	}
}


#salestier-pivot .pvtTable {
	min-width: 100%;
	margin: 0;
}


.stat-card {
	min-height: 300px;
	max-height: 600px;
	overflow-y: auto;
}

.card-info-row {
	&,
	*.MuiCardContent-root,
	&.MuiCardContent-root:last-child {
		padding: 8px;
	}
}

.mega-filter-wrapper {
	position: -webkit-sticky;
	position: sticky;
	top: 64px;
	z-index: 2;
}

@media screen and (max-width: 1280px) {
	.product-selecter-wrapper {
		padding-left: 1rem;
	}
}

.mega-filter-summary.MuiAccordionSummary-content,
.mega-filter-summary.MuiAccordionSummary-content.Mui-expanded
{
	margin: 0;
}
.mega-filter-section {
	&.MuiAccordion-root.Mui-expanded {
		margin: 0
	}
}

.mega-filter-states {
	&.MuiAccordionDetails-root {
		padding: {
			top: 0;
			bottom: 0;
		}
	}
}

.mega-filter-checkbox {
	&.MuiFormControlLabel-root {
		margin: {
			bottom: 0;
			right: 0;
		}
	}
	.MuiTypography-body1 {
		font-size: 0.75rem;
	}
	.MuiIconButton-root {
		padding: {
			top: 4px;
			top: 4px;
		}
	}
}


#product-selecter {
	label + .MuiInput-formControl {
	}
}

.x_cols {
	display: flex;
	// .x_col:nth-child(2n) {
	// }
	.x_row {
		overflow: hidden;
		min-height: 44px;
		&.active,
		.active {
			color: #0f5ee8;
		}
	}
	.x_cell {
		min-height: 100%;

	}
	.x_col:first-child {
		width: 260px;
		white-space: nowrap;
	}
}

.x_cell_head {
	width: 30px;
	height: 160px;
	position: relative;
	&_label {
		font-size: 14px;
		font-weight: 500;
		writing-mode:vertical-lr;
		height: 100%;
		position: absolute;
		white-space: nowrap;
		padding: 5px;
		margin-left: 5px;
	}
}


.x_cell_head {
	transform: translateY(0);
	transition: 3s;
}
.x_cell_head:hover {
	transform: translateY(calc(-100%));
}
.checkCircle {
	border-bottom: 1px solid black;
}
.activeCircle {
	cursor: pointer;
	width: 25px;
	height: 25px;
	border-radius: 1000px;
	background-color: #0f5ee8;

}

.inactiveCircle {
	cursor: pointer;
	width: 25px;
	border-radius: 1000px;
	height: 25px;
	background-color: #999;
}
.noCircle {
	width: 25px;
	height: 25px;
	background-color: #ffffff00;
}
.activeFieldsButton {
	display: inline-block;
	border-radius: 100px;
	height: 35px;
}
.activeFieldsButtonText {
	font-family: 'Poppins';
	font-weight: 600;
	color: #546E7A;
	margin-right: 10px;
	display: inline-block;
}
.activeFieldsBox::-webkit-scrollbar {
	height: 0px;
}

.textBox {
	overflow: hidden;
	position: relative;
	font-size: 12px;
	text-align: right;
}
