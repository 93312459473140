#salestier-pivot .pvtTable {
	font-family: "Poppins", sans-serif;
	.pvtRowLabel {
		font-weight: normal;
	}
	thead th {
		background-color: #0C45A6;
		color: #fff;
		font-weight: normal;
		font-size: 12px;
	}
	tr:hover {
		background:#FFD100;
	}
	tbody {
		th {
			background-color: transparent;
		}
		th,
		td {
			color: #000;
		}
	}
	tbody th:first-child {
		padding-left: 0;
		padding-right: 4px;
	}
	th,
	td {
		border-left-width: 0;
		border-right-width: 0;
	}
	th + td {
		border-left-width: 1px;
	}
}

.MuiDataGrid-columnHeader.reports-grid-header,
#salestier-pivot .MuiDataGrid-columnHeaders {
	background-color: #0C45A6;
	color: #fff;
	button {
		color: #fff;
	}
}

#salestier-pivot {
	.column-totals {
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		font-weight: bold;
	}
}

.MuiDataGrid-pinnedRows--top {
	.MuiDataGrid-row {
	  background-color: #020069 !important;
	  color: #ffffff !important;
	  font-weight: 700;

  
	  &:hover {
		background-color: #020069 !important;
		color: #ffffff !important;
		font-weight: 700;

	  }
	}
  }


.MuiDataGrid-row {
	&.totals-row {
		background-color: #0C45A6 !important;
		color: #fff;
		&:hover {
			background-color: #0C45A6 !important;
			color: #fff;
		}
	}

	// will need to update these colors later, they're a bit ugly atm
	$colors: (
		#0C45A6,                     
		desaturate(#0C45A6, 20%),     
		desaturate(#0C45A6, 40%),     
		desaturate(#0C45A6, 60%),     
		desaturate(#0C45A6, 80%),     
		#49D1DD,                      
		#00FFFF,                      
		#75B96D,                      
		#8676FF,                      
		#FF708B                       
	);

	@for $depth from 1 through 10 {
		&.depth-#{$depth} {
			$color: nth($colors, $depth);
			background-color: $color !important;
			color: #fff;
			
			&:hover {
				background-color: $color !important;
				color: #fff;
			}
		}
	}

	.MuiDataGrid-cell {
		&.muted {
			background-color: #F6F9FF;
			color: #999999;
		}
		& .positive-diff {
			color: green !important;
			font-weight: bold;
		}
		  & .negative-diff {
			color: red !important;
			font-weight: "bold";
		  }
	}
}

/* Just in case MUI still tries some sneaky stuff */
.MuiDataGrid-row:hover {
	background-color: inherit !important; /* Keeps row colors locked */
}
